import * as React from 'react';
import { Grid, Box, TextField, Button, Typography, Card, CardContent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, clearUser } from './redux/slices/userSlice';
import { setToken } from './redux/slices/authSlice';
import { GoogleLogin,googleLogout } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import ResponsiveAppBar from './components/navBarMui';

function LoginPage() {
  const navigate=useNavigate()
  const dispatch = useDispatch();

  const handleSubmit = async (obj) => {
//  
    dispatch(setToken(obj.credential));
   var {email,name,picture}=jwtDecode(obj.credential);
          dispatch(setUser({email,name,picture}));
        console.log(jwtDecode(obj.credential))  
          navigate('/cars')
    // try {
    //   const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/auth`, obj);
    //   console.log('Signup successful!', response.data);
        
   
    //   // Handle successful signup response here
    // } catch (error) {
    //   console.error('Error signing up:', error);
    //   // Handle error here
    // }
  };

  return (
    <>
    <div style={{position:'absolute',top:0,left:0,right:0,display:'none'}}>  <ResponsiveAppBar/></div>
    
      <Grid container sx={{ minHeight: '100vh' }}>
      {/* Image Section for Large Screens */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          backgroundImage: 'url(assets/images/banner.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: { xs: 'none', md: 'block' }, // Hide on small screens
        }}
      />

      {/* Login Form Section */}
       <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundImage: { xs: 'url(assets/images/banner.jpg)', md: 'url(https://img.freepik.com/free-vector/white-abstract-background_23-2148810352.jpg)' },
        //   'url(assets/images/banner.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',

          backgroundColor: { xs: 'rgba(255, 255, 255, 0.8)', md: 'transparent' }, // Background for small screens
          boxShadow: { xs: 3, md: 'none' }, // Card shadow on small screens
          borderRadius: 2, // Rounded corners for the card
          p: { xs: 2, sm: 4 }, // Padding for small screens
        }}
      >
        <Card
          sx={{
            width: '100%',
            maxWidth: 400,
            boxShadow: { xs: 3, md: 'none' }, // Card shadow on small screens, none on large screens
            p: { xs: 2, md: 0 }, // Remove padding on large screens
            backgroundColor: { md: 'white',xs: 'white', }, // Transparent card on large screens
          }}
        >
          <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ textAlign: 'center' }}>
              <img
                className="img-responsive"
                height="30px"
                src="assets/images/logo-light.png"
                alt="Logo"
              />
            </Box>
            <Typography variant="h5" component="h1" gutterBottom sx={{ alignSelf: 'start' }}>
              Sign In
            </Typography>
            {/* <TextField label="Email" variant="outlined" fullWidth required />
            <TextField label="Password" type="password" variant="outlined" fullWidth required />
            <Button variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
              Login
            </Button> */}
            <GoogleLogin
   onSuccess={credentialResponse => {
     handleSubmit(credentialResponse);

    
   }}
   onError={() => {
     console.log('Login Failed');
   }}
  //  useOneTap
  // style={{width:"100%"}}
 />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
    </>
  
  );
}

export default LoginPage;

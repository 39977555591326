import React, { useEffect, useState } from 'react';
// import Navbar from './navbar.js';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import {Button,Grid} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ReactHtmlParser from 'react-html-parser';
import { doc, getDoc } from '@firebase/firestore';
import { Skeleton } from '@mui/material';
import { db } from './components/firebase-config.js';
import { Helmet } from 'react-helmet';
import { useDispatch ,useSelector} from 'react-redux';
// import { addToCart, incrementQuantity, removeFromCart,decrementQuantity} from '../redux/features/cart/cartSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ResponsiveAppBar from './components/navBarMui.js';
// import Footer from './footer.js';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import FsLightbox from "fslightbox-react";
export default function Products() {
  const { id } = useParams();
//   const cartItems = useSelector(state => state.cart.items);
  const [product, setProduct] = useState({});
  const [quantity, setQuantity] = useState(1);
//   const isInCart = cartItems.some(item => item.name === product?.name);

  const [selectedImage, setSelectedImage] = useState('https://dev-to-uploads.s3.amazonaws.com/i/mrvsmk2pl3l8fwocbfhy.gif');
  const dispatch = useDispatch();

//   const handleAddToCart = () => {
//     if (!isInCart) {
//       dispatch(addToCart({ name: product?.name, image: selectedImage, link: window.location.href, quantity }));
//       toast.success(`Added ${product?.name} to cart!`, {
//         position: "top-right",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//       });
//       setQuantity(1); // Reset quantity after adding to cart
//     } else {
//       dispatch(removeFromCart({ name: product?.name }));
//       toast.info(`Removed ${product?.name} from cart.`, {
//         position: "top-right",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//       });
//     }// Reset quantity after adding to cart
//   };
//   const handleIncreaseQuantity = () => {
//     setQuantity(quantity + 1);
//   };

//   const handleDecreaseQuantity = () => {
//     if (quantity > 1) {
//       setQuantity(quantity - 1);
//     }
//   };

  

  useEffect(() => {

    const getProduct = async () => {
      const productRef = doc(db, 'cars', id);
      const productSnap = await getDoc(productRef);
     console.log("productData")
      if (productSnap.exists()) {
        const productData = { ...productSnap.data(), id: productSnap.id };
        console.log(productData)
        setProduct(productData);
        setSelectedImage(productData.images?.[0].url || '');
        console.log(productData);
      } else {
        console.log(`No such document with id ${id}!`);
      }
    };
    getProduct();
  }, [id]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };
useEffect(()=>{
window.scroll(0,0)
},[])
const [search,setSearch]=useState('')


const [isDrawerOpen, setDrawerOpen] = useState(false);

const toggleDrawer = (open) => (event) => {
  if (
    event.type === 'keydown' &&
    (event.key === 'Tab' || event.key === 'Shift')
  ) {
    return;
  }
  setDrawerOpen(open);
};

const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openViewer = (index) => {
    setCurrentImageIndex(index);
    setIsViewerOpen(true);
  };

//   const closeImgsViewer = () => {
//     setIsViewerOpen(false);
//   };

//   const gotoPrevImg = () => {
//     setCurrentImageIndex((prevIndex) => Math.max(prevIndex - 1, 0));
//   };

//   const gotoNextImg = () => {
//     setCurrentImageIndex((prevIndex) => {
//       const totalImages = selectedCar?.images?.length || 0;
//       if (prevIndex < totalImages - 1) {
//         return prevIndex + 1;
//       } else {
//         return prevIndex;
//       }
//     });
//   };
//   const [toggler, setToggler] = useState(false);
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1 // This is the index of the image you want to open (1-based index)
  });
  const [selectedCar, setSelectedCar] = useState(null);

  const openLightboxAtIndex = (index) => {
    setLightboxController({
      toggler: !lightboxController.toggler, // Toggle the state to open the lightbox
      slide: index + 1 // FsLightbox uses 1-based indexing
    });
  };
  return (<>
  <ResponsiveAppBar/>
    <div style={{minHeight:'100vh'}}>
       <ToastContainer />
         <Helmet>
        <meta name="description" content />
        <meta name="keywords" content="Kenya online Shopping, discount shopping, fast delivery, mobile phones, laptop, women’s fashion, men’s fashion, Samsung, OPPO, Vivo, Huawei, Apple, shoes, home appliance, Jumia, Black Friday, bags, Kilimall" />
        {/* <meta name="author" content="Your Name" /> */}
        <meta property="og:title" content={product?.name} />
        <meta property="og:description" content />
        <meta property="og:image" content={selectedImage} />
        {/* <meta name="viewport" content="width=device-width, initial-scale=1" /> */}
      </Helmet>
      {/* <Navbar setSearch2={setSearch} search2={search} /> */}
      <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', maxWidth: '100vw', overflow: 'hidden' }}>
        <div style={{ flex: 1, padding: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div
            style={{
              flex: 1,
              padding: 10,
              maxHeight: '60vh',
              minHeight: 200,
              height: '100vw',
              maxWidth: '100vw',
              minWidth: 340,
              backgroundColor: '#f8f8f8',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            
            <img
              src={selectedImage}
              alt="Product"
              onClick={() => openLightboxAtIndex(0)}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                
                display: 'block', // Remove any extra spacing below the image
                margin: '0 auto',
              }}
            />
              <FsLightbox
  toggler={lightboxController.toggler}
  // showThumbsOnMount={true}
  // disableThumbs={false}
  sources={product?.images?.map(item => ( item.url ))}
  thumbs={product?.images?.map(item => ( item.url ))}
  slide={lightboxController.slide} 
/>
       
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
            {product.images?.map((image, index) => (
              <img
                key={index}
                src={image.url}
                alt={`Thumbnail ${index}`}
                onClick={() => handleImageClick(image.url)}
                style={{
                  width: 50,
                  height: 50,
                  margin: 5,
                  objectFit:'cover',
                  cursor: 'pointer',
                  border: selectedImage === image.url ? '2px solid #000' : '2px solid transparent',
                }}
              />
            ))}
          </div>
        </div>
        <div style={{ flex: 1, minWidth: 340, padding: 10 }}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>

          {<>{product.manufacturer} {product.model}</>|| <Skeleton width={100} height={40} />}
          </Typography>
          <div style={{ paddingTop: 0 }}>
               {/* <Typography variant="subtitle">Description</Typography> */}
            <Typography variant="body2">

            {product.description ? ReactHtmlParser(product.description) :<>
                <Skeleton width={300} />
                <Skeleton width={300} />
                <Skeleton width={300} />
                <Skeleton width={300} />
            </> }
            </Typography>
          </div>  

               <Typography variant="subtitle">Specifications</Typography><br/>
          <Typography variant="body1" gutterBottom>
        Year: {product.year || <Skeleton width={100} />}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Engine Size: {product.engineSize ? `${product.engineSize} CC` : <Skeleton width={150} />}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Transmission: {product.transmission || <Skeleton width={100} />}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Status: {product.status || <Skeleton width={80} />}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Availability: {product.availabilityStatus || <Skeleton width={120} />}
      </Typography>
      <Button onClick={toggleDrawer(true)}>View More Details</Button>
              {/* <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex', padding: 0, paddingTop: 20, maxWidth: '100vw',flexWrap:"wrap" }}>
       {isInCart&& <div style={{ margin: 5 ,minWidth:150}}>
          <Button variant="outlined" size='small' onClick={()=>{ dispatch(decrementQuantity({ name: product.name }))}} disabled={cartItems?.filter(item => item.name === product?.name)[0]?.quantity === 1}
          >
            <RemoveIcon />
          </Button>
          <span style={{ padding: 10 }}>{isInCart && cartItems.filter(item => item.name === product?.name)[0].quantity}</span>
          <Button variant="outlined" size='small'  onClick={()=>{ dispatch(incrementQuantity({ name: product.name }))}}
          >
            <AddIcon />
          </Button>
        </div>}
        <div style={{flex:1}}></div>
        <div style={{minWidth:150}}>
        <Button 
              variant={isInCart ? "outlined" : "contained"}  size='small' 
              color={isInCart ? "error" : "primary"} 
              onClick={handleAddToCart} 
              style={{ margin: 5 }}
            >
              {isInCart ? "Remove from Cart" : "Add to Cart"}
            </Button></div>
      </div> <br/>  */}
                 
 
       
          {/* <WhatsAppButton phoneNumber="+254727216469" message={`I want this product ${product.name}`} /> */}
              </div>
        <div style={{ width: 300 }}>
          {/* <Typography variant='h6'>Delivery</Typography> */}
        </div>
        <br/>
      </div> <div style={{padding:10,display: "flex",justifyContent:"start",width:"100%"}}>
        <div style={{maxWidth:"1200px",width:"100%"}}>

     
<Typography variant='subtitle' >
            Other Specifications
          </Typography>
          <Typography variant='body1' >
          {ReactHtmlParser(product.specifications) }</Typography>
          </div>   </div>
      <div style={{ minHeight: '100px' }}></div>
      {/* <BottomTabs/> */}
     {/* <Footer/> */}

    </div>
    
    <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 350, p: 2 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
           <Typography variant="h4" component="div" gutterBottom>
        {product.carName}
      </Typography>
      
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            <strong>Type:</strong>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            {product.carType}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            <strong>Manufacturer:</strong>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            {product.manufacturer}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            <strong>Model:</strong>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            {product.model}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            <strong>Year:</strong>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            {product.year}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            <strong>Fuel Type:</strong>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            {product.fuelType}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            <strong>Cylinders:</strong>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            {product.cylinders}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            <strong>Transmission:</strong>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            {product.transmission}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            <strong>Engine Size:</strong>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            {product.engineSize}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            <strong>Acceleration:</strong>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            {product.acceleration}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            <strong>Mileage:</strong>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            {product.mileage}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            <strong>Horse Power:</strong>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            {product.horsePower}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            <strong>Status:</strong>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            {product.status}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            <strong>Wheels Drive:</strong>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            {product.wheelsDrive}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            <strong>Torque:</strong>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            {product.torque}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            <strong>Availability Status:</strong>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" component="div">
            {product.availabilityStatus}
          </Typography>
        </Grid>
      </Grid>
        </Box>
      </Drawer>
          
          </>
  );
}

const WhatsAppButton = ({ phoneNumber, message }) => {
  // Format the phone number and message into the WhatsApp URL
  const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <Button
      variant="contained"
      color="primary"
      href={whatsappURL}
      target="_blank"
      rel="noopener noreferrer"
      style={{ backgroundColor: '#25D366', color: 'white' }}
    >
      Chat on WhatsApp
    </Button>
  );
};

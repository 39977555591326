
import { useState, useEffect } from 'react';
import { Card,IconButton, CardContent, CardMedia, Typography, Grid,Chip, CircularProgress } from '@mui/material';
import  { db, storage } from './components/firebase-config'
import {limit,startAfter,onSnapshot, collection,getDocs,query,doc,where,addDoc,deleteDoc,updateDoc, orderBy, startAt, endAt } from '@firebase/firestore'
import ResponsiveAppBar from './components/navBarMui';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import axios from 'axios'
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Close } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import FsLightbox from "fslightbox-react";
import ImgsViewer from "@haz3l/react-images-viewer";
import ImageGallery from "react-image-gallery";
import { Link } from 'react-router-dom';
const StyledDrawer = styled(Drawer)({
  width: 250,
});

const StyledButton = styled(Button)({
  // Your custom styles for Button
});

const ModalContent = styled('div')({
  padding: '16px', // Example padding
});
const useFetchCars = () => {

    const [cars, setCars] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchCars = async () => {
        try {
          const querySnapshot = await getDocs(collection(db, 'cars'));
          const carList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setCars(carList);
        } catch (error) {
          console.error('Error fetching cars:', error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchCars();
    }, []);
  
    return { cars, loading };
  };
export default function CarListing() {
  return (
    <div>
        
        <CarList />
    </div>
  )
}

const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };


const CarList = () => {
    const [cars, setCars] = useState([]);
  const getStatusColor = (status) => {
    switch (status) {
      case 'New':
        return 'success';
      case 'Foreign Used':
        return 'primary';
      case 'Kenyan Used':
        return 'secondary';
      default:
        return 'default';
    }
  };


    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(false);
    const carsPerPage = 20;
    const getCars = async (loadMore = false) => {
        // setLoading(true);
        let carQuery;
        setLoading(true)
        if (loadMore && lastVisible) {
          carQuery = query(
            collection(db, 'cars'),
            startAfter(lastVisible),
            limit(carsPerPage)
          );
        } else {
          carQuery = query(collection(db, 'cars'), limit(carsPerPage));
        }
      
        const data = await getDocs(carQuery);
        const cars = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        const shuffledCars = shuffleArray(cars);
      console.log(cars)
      setLoading(false)
        if (loadMore) {
          setCars((prev) => [...prev, ...shuffledCars]);
        } else {
          setCars(shuffledCars);
        }
      
        setLastVisible(data.docs[data.docs.length - 1]);
      }
      useEffect(()=>{
          getCars();
      },[])
//   const { cars, loading } = useFetchCars();

const [open, setOpen] = useState(false);
const [selectedCar, setSelectedCar] = useState(null);

const handleOpen = (car) => {
  setSelectedCar(car);
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
  setSelectedCar(null);
};
const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openViewer = (index) => {
    setCurrentImageIndex(index);
    setIsViewerOpen(true);
  };

  const closeImgsViewer = () => {
    setIsViewerOpen(false);
  };

  const gotoPrevImg = () => {
    setCurrentImageIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const gotoNextImg = () => {
    setCurrentImageIndex((prevIndex) => {
      const totalImages = selectedCar?.images?.length || 0;
      if (prevIndex < totalImages - 1) {
        return prevIndex + 1;
      } else {
        return prevIndex;
      }
    });
  };
  const [toggler, setToggler] = useState(false);
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1 // This is the index of the image you want to open (1-based index)
  });

  const openLightboxAtIndex = (index) => {
    setLightboxController({
      toggler: !lightboxController.toggler, // Toggle the state to open the lightbox
      slide: index + 1 // FsLightbox uses 1-based indexing
    });
  };
  return (<>
  <ResponsiveAppBar/>
   {loading==true &&<div style={{height:'90vh',display:"flex",justifyContent:'center',alignItems:"center"}}><CircularProgress height='100px' /></div>}
    <div style={{display:"flex",justifyContent:'center',padding:15,background:"#E0E3EA",minHeight:'100vh'}}>
     
      <Grid container spacing={3} style={{maxWidth:1200,flex:1}}>
      
      {cars.map(car => {
        const coverImage = car.images.find(img => img.isCover)?.url || car.images[0]?.url;

        return (
       
          <Grid item xs={12} sm={6} md={4} key={car.id}>   <Link target="_blank" rel="noopener noreferrer"  underline="none"  style={{ textDecoration: 'none' }} to={`../../product-detail/${car.id}/${car.carName.trim().replaceAll("-", " ").replaceAll(/\s+/g, " ").replaceAll(" ", "-").split('/')[0]}`}>
          
            <Card style={{position:'relative'}} onClick={()=>handleOpen(car)}> 
              {coverImage && (
                <CardMedia
                  component="img"
                  height="200"
                  image={coverImage}
                  alt={`${car.manufacturer} ${car.model}`}
                />
              )}
                <Chip 
                    label={`${car.availabilityStatus}`} 
                    color="default" 
                     size='small'
                     style={{position:"absolute",top:10,right:10,backgroundColor:car.availabilityStatus=='Available'?"#B2DFDB":"#FFCDD2"}}
                  />
         <CardContent>
                <Typography variant="h6" noWrap  component={'div'} style={{alignItems:'center',fontSize:17, marginTop: '0px', display: 'flex', flexWrap: 'wrap', gap: '8px'}}>
                  {car.manufacturer} {car.model}
                  {/* <div style={{ marginTop: '10px', display: 'flex', flexWrap: 'wrap', gap: '8px' }}> */}
                  <Chip 
                    label={`${car.year}`} 
                    color="default" 
                     size='small'
                  />
                  {/* <div style={{flex:1}}></div> */}
                  <Chip 
                    label={car.status} 
                    color="default" 
                     size='small'
                  />
                </Typography>

                <Typography variant='body1' style={{fontSize:12}}>
                The Subaru Forester SK9! It is specced with Eyesight adaptive cruise control, Front & side canera views, Snow Dirt & Snow Mud modes, symmetrical AWD setup..
                </Typography>
                <div style={{ marginTop: '10px', display: 'flex', flexWrap: 'wrap',justifyContent:'space-between', gap: '8px' }}>
                  <Chip 
                    label={`${car.engineSize} CC`} 
                    color="default" 
                    size='small'
                    // style={{backgroundColor:"#BBDEFB"}}
                     
                  />
                 
                
                  <Chip 
                    label={car.transmission} 
                    color="default" 
                     size='small'
                     style={{backgroundColor:"#C5CAE9"}}
                     
                  />
                </div>
              </CardContent>
            </Card></Link>
          </Grid>
        );
      })}
    </Grid>
    </div>
    <StyledDrawer
        anchor="right"
        open={open}
        onClose={handleClose}
        style={{position:'relative'}}
      >
      
        <ModalContent style={{maxWidth:500,padding:10,paddingBottom:140,display:'flex',minHeight:'100vh',flexDirection:'column',alignItems:'center',}}>
        {selectedCar ? (
    <div style={{ width: "100%", maxWidth: 500,  }}>
      <IconButton onClick={handleClose} style={{ float: 'right' }}>
        <Close />
      </IconButton>
      <Typography variant="h5" gutterBottom>
        {selectedCar.manufacturer} {selectedCar.model}
      </Typography>

      {/* Main Image */}
      {selectedCar.images && selectedCar.images.length > 0 ? (
        <CardMedia
          component="img"
          height="200"
          image={selectedCar.images.find(img => img.isCover)?.url || selectedCar.images[0]?.url}
          alt={`${selectedCar.manufacturer} ${selectedCar.model}`}
        />
      ) : (
        <Skeleton variant="rectangular" width="100%" height={200} />
      )}

      <Typography variant="body1" gutterBottom>
        Year: {selectedCar.year || <Skeleton width={100} />}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Engine Size: {selectedCar.engineSize ? `${selectedCar.engineSize} CC` : <Skeleton width={150} />}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Transmission: {selectedCar.transmission || <Skeleton width={100} />}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Status: {selectedCar.status || <Skeleton width={80} />}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Availability: {selectedCar.availabilityStatus || <Skeleton width={120} />}
      </Typography>

      {/* Additional Images */}
      <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
        Additional Images
      </Typography>
      <Grid container spacing={2}>
        {selectedCar.images && selectedCar.images.length > 1 ? (
          selectedCar.images.map((img, index) => (
            <Grid item xs={6} key={index}>
              <Card onClick={() => openLightboxAtIndex(index)} >
              {/* onClick={() => openViewer(index)}> */}
                <CardMedia
                  component="img"
                  height="100"
                  image={img.url}
                  alt={`Image ${index + 1}`}
                />
              </Card>
            </Grid>
  
          ))
        ) : (
          <>
            <Grid item xs={6}>
              <Skeleton variant="rectangular" width="100%" height={100} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rectangular" width="100%" height={100} />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  ) : (
    <div style={{ width: "100%", maxWidth: 500, padding: 20 }}>
      <Skeleton variant="rectangular" width="100%" height={200} style={{ marginBottom: 16 }} />
      <Skeleton variant="text" width="80%" />
      <Skeleton variant="text" width="60%" />
      <Skeleton variant="text" width="40%" />
      <Skeleton variant="text" width="70%" />
    </div>
  )}
  {/* {JSON.stringify(selectedCar?.images?.map(item => ({ src: item.url })))} */}
  {/* <ImageGallery style={{width:400}} items={selectedCar?.images?.map(item => ({ original: item.url,thumbnail:item.url }))} /> */}
    <ImgsViewer
    imgs={selectedCar?.images?.map(item => ({ src: item.url }))}
    isOpen={isViewerOpen}
    onClickPrev={gotoPrevImg}
    onClickNext={gotoNextImg}
    onClose={closeImgsViewer}
    currentImage={currentImageIndex}
  />
  <FsLightbox
  toggler={lightboxController.toggler}
  // showThumbsOnMount={true}
  // disableThumbs={false}
  sources={selectedCar?.images?.map(item => ( item.url ))}
  thumbs={selectedCar?.images?.map(item => ( item.url ))}
  slide={lightboxController.slide} 
/>
             
      </ModalContent>
</StyledDrawer>
    </>
  );
};



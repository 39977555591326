// import logo from './logo.svg';
import './App.css';
import React,{useEffect,useState} from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
  import { BrowserRouter,Routes,Route,Link, useNavigate } from 'react-router-dom';
  import Product from './components/products'
  import Home from './components/home'
  import Admin from './components/admin'
import Category  from './components/category'
import {
  Home as HomeIcon,
  List as ListIcon,
  Person as PersonIcon,
  ShoppingCart as ShoppingCartIcon,
  Chat as ChatIcon,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Test from './Test';
import CarListing from './carlisting';
import Login from './login';
import ProductDetail from './carview';
function App() {

  return (
    // <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
       
<BrowserRouter>

<Routes>
<Route path='/' element={<Login/>}  />
  <Route path='/add' element={<Test/>}  />
  <Route path='/cars' element={<CarListing/>}  />
  <Route path='/product-detail/:id/:slug' element={<ProductDetail/>}  />
  {/* <Route path='/product/:id/:slug' element={<Product/>}  />
  
  <Route path='/cart' element={<Cart/>}  />
  <Route path='/admin' element={<Admin/>}  />
  <Route path='/orders' element={<OrdersPage/>}  />
  <Route path='*' element={<Home />} /> */}
</Routes>

</BrowserRouter>
// </GoogleOAuthProvider>

   
  );
}

export default App;

import React, { useState, useEffect,useRef} from 'react';
import { Autocomplete,IconButton,Chip , CircularProgress,TextField, Grid, Paper, Button, Card, CardMedia, CardContent, Typography, FormControl, FormControlLabel, Checkbox, MenuItem, Select, InputLabel } from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import  { db, storage } from './components/firebase-config'
import {limit,startAfter,onSnapshot, collection,getDocs,query,doc,where,addDoc,deleteDoc,updateDoc, orderBy, startAt, endAt } from '@firebase/firestore'
import { ref, uploadBytes, getDownloadURL, deleteObject  } from '@firebase/storage';
import ResponsiveAppBar from './components/navBarMui';
import { useNavigate } from 'react-router-dom';
const CarSelector = () => {
  const [manufacturer, setManufacturer] = useState(null);
  const [model, setModel] = useState(null);
  const [year, setYear] = useState(null);
  const [fuelType, setFuelType] = useState(null);
  const [cylinders, setCylinders] = useState(null);
  const [transmission, setTransmission] = useState(null);
  const [engineSize, setEngineSize] = useState('');
  const [acceleration, setAcceleration] = useState('');
  const [mileage, setMileage] = useState('');
  const [horsePower, setHorsePower] = useState('');
  const [status, setStatus] = useState(null);
  const [wheelsDrive, setWheelsDrive] = useState(null); // State for Number of Wheels Drive
  const [torque, setTorque] = useState(''); // State for Torque
  const [availabilityStatus, setAvailabilityStatus] = useState(null); // State for Availability Status
  const [coverImage, setCoverImage] = useState(null); // State for Cover Image
  const [coverImagePreview, setCoverImagePreview] = useState(''); // State for Cover Image Preview

  const [manufacturers, setManufacturers] = useState([]);
  const [models, setModels] = useState([]);
  const [years, setYears] = useState([]);

  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  // Predefined options for fuel type, cylinders, transmission, status, wheels drive, and availability status
  const fuelTypeOptions = ['Gasoline', 'Diesel', 'Electric', 'Hybrid', 'Hydrogen'];
  const cylinderOptions = ['2', '4', '6', '8', '10', '12'];
  const transmissionOptions = ['Automatic', 'Manual', 'CVT', 'Semi-Automatic', 'Dual-Clutch'];
  const statusOptions = ['New', 'Foreign Used', 'Kenyan Used'];
  const wheelsDriveOptions = ['2', '4']; // Number of Wheels Drive options
  const availabilityStatusOptions = ['Available', 'Sold', 'Pre-Order']; // Availability Status options

  useEffect(() => {
    // Fetch manufacturers on component mount
    const fetchManufacturers = async () => {
      try {
        const response = await axios.get('https://vpic.nhtsa.dot.gov/api/vehicles/GetAllMakes?format=json');
        setManufacturers(response.data.Results);
      } catch (error) {
        console.error('Error fetching manufacturers:', error);
      }
    };
    fetchManufacturers();
  }, []);

  useEffect(() => {
    if (manufacturer) {
      // Fetch models based on selected manufacturer with debouncing
      const fetchModels = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMake/${manufacturer.Make_Name}?format=json`);
          setModels(response.data.Results);
        } catch (error) {
          console.error('Error fetching models:', error);
        } finally {
          setLoading(false);
        }
      };

      if (debounceTimeout) clearTimeout(debounceTimeout);

      const newTimeout = setTimeout(fetchModels, 500); // Delay of 500ms
      setDebounceTimeout(newTimeout);
    } else {
      setModels([]);
      setModel(null);
      setYears([]);
      setYear(null);
    }
  }, [manufacturer]);

  useEffect(() => {
    if (model) {
      // Generate last 10 years dynamically
      const currentYear = new Date().getFullYear();
      const last10Years = Array.from({ length: 10 }, (_, index) => ({
        label: (currentYear - index).toString(),
        value: currentYear - index,
      }));
      setYears(last10Years);
    } else {
      setYears([]);
      setYear(null);
    }
  }, [model]);

 


  const [images, setImages] = useState([]); // State to store uploaded images
  const [imageCategories, setImageCategories] = useState({}); // State to store image categories and cover photo selection

  const imageCategoryOptions = [ 'Interior', 'Exterior'];

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map(file => ({
      file,
      preview: URL.createObjectURL(file),
      category: 'Exterior', // Default category
      isCoverPhoto: false // Default cover photo status
    }));
    setImages(prevImages => [...newImages, ...prevImages]); // Add new images to the beginning of the list
  };

  const handleCategoryChange = (index, category) => {
    const updatedImages = [...images];
    updatedImages[index].category = category;
    setImages(updatedImages);
  };

  const handleCoverPhotoChange = (index) => {
    const updatedImages = images.map((img, i) => ({
      ...img,
      isCoverPhoto: i === index // Set cover photo status for the selected image
    }));
    setImages(updatedImages);
  };
  const [specifications, setSpecifications] = useState(''); 
  const [carName, setCarName] = useState(''); // State for car name
  const [carType, setCarType] = useState(''); // State for car type
  const [description, setDescription] = useState(''); // State for car description
const navigate=useNavigate()
  const carTypeOptions = [
    'Sedan',
    'SUV',
    'Hatchback',
    'Coupe',
    'Convertible',
    'Wagon',
    'Minivan',
    'Pickup Truck',
    'Sports Car',
    'Luxury Car',
    'Other'
  ];
  const handleRemoveImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
  };
  const handleSubmit = async () => {
    try {
      // Upload images and get download URLs
      const imagePromises = images.map(async (image) => {
        console.log(image)
        const imageRef = ref(storage, `images/${image.file.name}`);
        await uploadBytes(imageRef, image.file);
        const downloadURL = await getDownloadURL(imageRef);
        return {
          url: downloadURL,
          category: image.category,
          isCoverPhoto: image.isCoverPhoto
        };
      });
    //   const imageUrls = await Promise.all(
    //     images.map(async (file, index) => {
    //         console.log(file)
    //       const storageRef = ref(storage, `products/${Date.now()}_${index}_${file.name}`);
    //       await uploadBytes(storageRef, file);
    //       const downloadURL = await getDownloadURL(storageRef);
    //       return downloadURL;
    //     })
    //   );
      const imageData = await Promise.all(imagePromises);
console.log({
    carName,
    carType,
    description,
    specifications,
    manufacturer: manufacturer ? manufacturer.Make_Name : null,
    model: model ? model.Model_Name : null,
    year: year ? year.value : null,
    fuelType,
    cylinders,
    transmission,
    engineSize,
    acceleration,
    mileage,
    horsePower,
    status,
    wheelsDrive,
    torque,
    availabilityStatus,
    images: imageData
  })
      // Save data to Firestore
    //   await addDoc(collection(db, 'products'),{...product,images:imageUrls,timestamp: new Date() })
      await addDoc(collection(db, 'cars'), {
        carName,
        carType,
        description,
        specifications,
        manufacturer: manufacturer ? manufacturer.Make_Name : null,
        model: model ? model.Model_Name : null,
        year: year ? year.value : null,
        fuelType,
        cylinders,
        transmission,
        engineSize,
        acceleration,
        mileage,
        horsePower,
        status,
        wheelsDrive,
        torque,
        availabilityStatus,
        images: imageData,timestamp: new Date()
      });
navigate('/cars')
      alert('Car details and images uploaded successfully!');
    } catch (error) {
      console.error('Error uploading data:', error);
      alert('Error uploading data');
    }
  };
  return (
    <>
    <ResponsiveAppBar/>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px',padding:15,background:"#E0E3EA"}}>
      <Paper style={{ padding: '16px', width: '100%', maxWidth: '800px', marginBottom: '16px' }}>
        <Typography variant="h6" gutterBottom>
          Car Information
        </Typography>
        <TextField
          label="Car Name"
          value={carName}
          onChange={(e) => setCarName(e.target.value)}
          fullWidth
          style={{ marginBottom: '16px' }}
        />
        <FormControl fullWidth style={{ marginBottom: '16px' }}>
          <InputLabel>Car Type</InputLabel>
          <Select
            value={carType}
            onChange={(e) => setCarType(e.target.value)}
            label="Car Type"
          >
            {carTypeOptions.map((type, index) => (
              <MenuItem key={index} value={type}>{type}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <div style={{ width: '100%', maxHeight: 300, overflow: 'auto' }}>
        <Typography variant="h6" gutterBottom>
        Description
        </Typography>
          <CKEditor
            editor={ClassicEditor}
            data={description}
            onChange={(event, editor) => {
              const data = editor.getData();
              setDescription(data);
            }}
          />
        </div>
      </Paper>
      <Paper style={{ padding: '16px', width: '100%', maxWidth: '800px', marginBottom: '16px' }}>
      <Typography variant="h6" gutterBottom>
      Vehicle Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={manufacturers}
              getOptionLabel={(option) => option.Make_Name}
              onChange={(event, newValue) => {
                setManufacturer(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              inputValue={inputValue}
              renderInput={(params) => <TextField {...params} label="Select Manufacturer" fullWidth />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={models}
              getOptionLabel={(option) => option.Model_Name}
              onChange={(event, newValue) => {
                setModel(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Model"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  fullWidth
                />
              )}
              disabled={!manufacturer}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={years}
              getOptionLabel={(option) => option.label}
              onChange={(event, newValue) => {
                setYear(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Select Year" fullWidth />}
              disabled={!model}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={fuelTypeOptions}
              getOptionLabel={(option) => option}
              onChange={(event, newValue) => {
                setFuelType(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Select Fuel Type" fullWidth />}
              disabled={!model}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={cylinderOptions}
              getOptionLabel={(option) => option}
              onChange={(event, newValue) => {
                setCylinders(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Select Cylinders" fullWidth />}
              disabled={!model}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={transmissionOptions}
              getOptionLabel={(option) => option}
              onChange={(event, newValue) => {
                setTransmission(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Select Transmission" fullWidth />}
              disabled={!model}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Engine Size (CC)"
              value={engineSize}
              onChange={(e) => setEngineSize(e.target.value)}
              disabled={!model}
              style={{ maxWidth: '400px' }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Acceleration (0-60 mph in seconds)"
              value={acceleration}
              onChange={(e) => setAcceleration(e.target.value)}
              disabled={!model}
              style={{ maxWidth: '400px' }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Mileage (miles per gallon)"
              value={mileage}
              onChange={(e) => setMileage(e.target.value)}
              disabled={!model}
              style={{ maxWidth: '400px' }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Horsepower (HP)"
              value={horsePower}
              onChange={(e) => setHorsePower(e.target.value)}
              disabled={!model}
              style={{ maxWidth: '400px' }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={wheelsDriveOptions}
              getOptionLabel={(option) => option}
              onChange={(event, newValue) => {
                setWheelsDrive(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Number of Wheels Drive" fullWidth />}
              disabled={!model}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Torque (Nm)"
              value={torque}
              onChange={(e) => setTorque(e.target.value)}
              disabled={!model}
              style={{ maxWidth: '400px' }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={availabilityStatusOptions}
              getOptionLabel={(option) => option}
              onChange={(event, newValue) => {
                setAvailabilityStatus(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Availability Status" fullWidth />}
              disabled={!model}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={statusOptions}
              getOptionLabel={(option) => option}
              onChange={(event, newValue) => {
                setStatus(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Select Status" fullWidth />}
              disabled={!model}
            />
          </Grid>
        </Grid>
      </Paper>

      {/* New Paper section for image upload */}
        {/* New Paper section for specifications */}
        <Paper style={{ padding: '16px', width: '100%', maxWidth: '800px', marginTop: '16px' }}>
        <Typography variant="h6" gutterBottom>
          Post Other Specifications
        </Typography>
        <div style={{ width: '100%', maxHeight: 300, overflow: 'auto' }}>
          <CKEditor
            editor={ClassicEditor}
            data={specifications}
            onChange={(event, editor) => {
              const data = editor.getData();
              setSpecifications(data);
            }}
          />
        </div>
      </Paper>
     {/* New Paper section for image upload and display */}
     <Paper style={{ padding: '16px', width: '100%', maxWidth: '800px', marginTop: '16px' }}>
        <Typography variant="h6" gutterBottom>
          Upload and Manage Images
        </Typography>
        <input
          accept="image/*"
          id="upload-images"
          type="file"
          multiple
          style={{ display: 'none' }}
          onChange={handleImageUpload}
        />
        <label htmlFor="upload-images">
          <Button variant="contained" color="primary" component="span">
            Upload Images
          </Button>
        </label>

        {images.length > 0 && (
          <Grid container spacing={2} style={{ marginTop: '16px' }}>
            {images.map((image, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative' }}>
                  <CardMedia
                    component="img"
                    alt={`Image ${index}`}
                    height="200"
                    image={image.preview}
                    title={`Image ${index}`}
                    style={{ objectFit: 'cover', width: '200px', height: '200px' }}
                  />
                  <IconButton
                    onClick={() => handleRemoveImage(index)}
                    style={{ position: 'absolute', top: '8px', right: '8px', color: 'white' }}
                  >
                    <CloseIcon style={{color:'black'}} />
                  </IconButton>
                  <CardContent style={{ flex: '1 0 auto', marginLeft: '16px' }}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{ whiteSpace: 'nowrap',width:120, overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      {image.file.name}
                    </Typography>
                    <FormControl component="fieldset" style={{ marginTop: '8px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={image.isCoverPhoto}
                            onChange={() => handleCoverPhotoChange(index)}
                          />
                        }
                        label="Cover Photo"
                      />
                      <Autocomplete
                        options={imageCategoryOptions}
                        getOptionLabel={(option) => option}
                        value={image.category}
                        onChange={(event, newValue) => handleCategoryChange(index, newValue)}
                        renderInput={(params) => <TextField {...params} label="Category" />}
                        style={{ marginTop: '8px' }}
                      />
                    </FormControl>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Paper>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        style={{ marginTop: '16px' }}
      >
        Submit
      </Button>
    </div>
    </>
    
  );
};

export default CarSelector;